<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          Harita Puan Verileri
        </b-card-title>
        <div>
          <b-button
            to="/map-scores/add"
            variant="primary"
          >
            <feather-icon icon="PlusIcon" />
            Oluştur
          </b-button>
        </div>
      </b-card-header>
      <template v-if="dataList.length > 0">
        <b-table
          :fields="fields"
          :items="dataList"
          responsive="sm"
          striped
        >
          <template #cell(brand)="data">
            <div v-if="data.item.week">
              <small class="text-warning">{{ data.item.month }}-{{ data.item.week }}</small>
            </div>
            {{ data.item.brand }}
            <div v-if="data.item.location">
              <small class="text-primary">{{ data.item.location }}</small>
            </div>
          </template>
          <template #cell(control)="data">
            <b-button
              variant="primary"
              size="sm"
              :to="'/map-scores/edit/' + data.item.id"
            >
              Güncelle
            </b-button>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </template>
      <b-alert
        v-else
        show
        variant="info"
        class="m-2"
      >
        <div class="alert-body text-center">
          Kayıtlı veri bulunmuyor.
        </div>
      </b-alert>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BButton, BAlert, BTable, BCardFooter, BPagination,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BPagination,
    BCardFooter,
    BTable,
    BAlert,
    BCard,
    BCardHeader,
    BCardTitle,
    BButton,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'brand',
          label: 'Marka',
        },
        {
          key: 'score',
          label: 'Puan',
        },
        {
          key: 'username',
          label: 'Danışman',
          thStyle: { width: '200px' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '200px' },
        },
      ],
      dataQuery: {
        select: [
          'com_map_scores.id                      AS id',
          'com_user.name                          AS username',
          'com_brand.name                         AS brand',
          'com_location.title                     AS location',
          'com_map_scores.score                   AS score',
          'com_map_scores.date                    AS date',
          'com_user.name                          AS username',
          'com_user.created                       AS created',
        ],
        where: {},
        limit: 25,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['mapScores/dataList']
    },
    dataCount() {
      return this.$store.getters['mapScores/dataCounts']
    },
    saveStatus() {
      return this.$store.getters['mapScores/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 25
      this.pagination(page)
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('mapScores/getDataList', this.dataQuery)
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
